<template>
  <div id="issue">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
          />星级申报
        </van-col>
        <!-- <van-col span="14" class="header-title" style="text-align: right">
            <div
              class="submit-btn"
              @click="post_notifications"
            >
              立即申请
            </div>
          </van-col> -->
      </van-row>
    </div>
    <div class="issue-wrapper">
      <div class="issue-container">
        <div class="flex">
          <label>旅行社</label>
          <div class="flex-box">
            {{ agencyMessage.name }}
          </div>
        </div>
        <div class="flex">
          <label>星级选择</label>
          <div class="flex-box">
            <select v-model="starNum" @change="slectStar($event)">
              <option value="3">三星</option>
              <option value="4">四星</option>
              <option value="5">五星</option>
            </select>
          </div>
        </div>

        <div class="flex">
          <label>描述</label>
          <div class="flex-box">
            <textarea
              class="reply-text-area"
              v-model="description"
              placeholder="请填写描述"
              name=""
              id=""
              cols="30"
            ></textarea>
          </div>
        </div>
        <div class="flex">
          <van-uploader
            max-count="1"
            v-model="fileList"
            accept="image/*"
            :after-read="fnUploadRequest"
          />
        </div>
        <div class="btn" @click="post_notifications">立即申请</div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import api from "@/services/apis.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import oss from "@/utils/oss";
import { Toast } from "vant";
const toolbarOptions = [
  // ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
  ["bold", "italic", "underline"],
  // ["blockquote", "code-block"], // 引用  代码块
  // [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
  // [{ indent: "-1" }, { indent: "+1" }], // 缩进
  // [{ size: ["small", false, "large", "huge"] }], // 字体大小
  // [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  // [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  // [{ font: [] }], // 字体种类
  // [{ align: [] }], // 对齐方式
  // ["clean"], // 清除文本格式
  ["link", "image", "video"], // 链接、图片、视频
];
export default {
  name: "issue",
  data() {
    const _self = this;
    return {
      editorHtml: "",
      editorOption: {
        placeholder: "在这儿写文章～",
        theme: "snow",
        modules: {
          // imageResize: {
          //   displayStyles: {
          //     backgroundColor: "black",
          //     border: "none",
          //     color: "white"
          //   },
          //   modules: ["Resize", "DisplaySize", "Toolbar"]
          // },
          toolbar: {
            container: toolbarOptions,
            handlers: {
              image: function (value) {
                if (value) {
                  _self.type = "image";
                  document.querySelector("#quill-upload").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              video: function (value) {
                if (value) {
                  _self.type = "video";
                  document.querySelector("#quill-upload").click();
                } else {
                  this.quill.format("video", false);
                }
              },
            },
          },
        },
      },
      active: 0,
      result: [],
      show_scope: [],
      show_scope1: [],
      notifications: {
        notification_type: 1,
        show_scope: 0,
        title: "",
        content: "",
      },
      notifications1: {
        notification_type: 2,
        show_scope: 0,
        title: "",
        file_url: "",
      },
      // 旅行社信息
      agencyMessage: {},
      starNum: 3,
      description: "",
      fileList: [],
      type: "image",
    };
  },
  created() {
    this.getagency();
  },
  methods: {
    // 获取当前旅行社信息
    async getagency() {
      const res = await api.get_current_company(this.query);
      if (res.data) {
        console.log(res.data.company);
        this.agencyMessage = res.data.company;
      }
    },
    // 返回上一层
    onClickLeft() {
      this.$router.go(-1);
    },
    // 选择星级
    slectStar(e) {
      console.log("当前选择的星级", e.target.value);
    },

    // 点击申请
    async post_notifications() {
      if (this.description == "") {
        Toast("请填写描述");
        return false;
      }
      let addparms = {
        company_star_list: {
          travel_company_id: this.agencyMessage.id,
          description: this.description,
          star: this.starNum,
          images: [],
        },
      };
      if (this.fileList[0]) {
        addparms.company_star_list.images = this.fileList.map(
          (item) => item.url
        );
      }
      // console.log('星级申请状态',addparms)
      const res = await this.$apis.add_star_mes(addparms);
      // console.log('星级申请状态www',res)
      if (res.status == 200) {
        Toast("操作成功!");
        this.$router.go(-1);
      } else {
        Toast(res.message);
      }
    },

    //   图片和文件上传
    fnUploadRequest(option) {
      const _self = this;
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        Toast.clear();
        _self.fileList = [{ url: res.fileUrl[0].split("?")[0] }];
      });
    },
    //   富文本相关-暂时不用
    fnUploadRequestInEditor(option) {
      Toast.loading({
        message: "上传中...",
        forbidClick: true,
      });
      oss.ossUploadFile(option).then((res) => {
        const _self = this;
        let quill = this.$refs.myQuillEditor.quill;
        let url = res.fileUrl[0].split("?")[0];
        if (url) {
          let length = quill.getSelection().index;
          quill.insertEmbed(length, _self.type, url);
          quill.setSelection(length + 1);
        }
        Toast.clear();
      });
    },
  },
  components: {
    quillEditor,
  },
};
</script>
  
  <style scoped>
#issue {
  height: 100vh;
  background: #f3f3f3;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.issue-wrapper {
  height: 88vh;
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  padding-top: 0.3rem;
  width: 95%;
  margin: 0 auto;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.issue-container {
  background: #fff;
  margin-top: 0.2rem;
  padding: 0.2rem;
}
.quill-editor {
  height: 40vh !important;
}
.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 5px;
  position: relative;
}
.flex-box {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  min-width: 0;
  font-size: 14px;
  color: #333;
}
.flex label {
  color: #000000;
  font-size: 0.3rem;
  width: 80px;
}

.flex-box input {
  background: none;
  border: none;
  padding: 0.1rem 0;
  height: 1rem;
  line-height: 1rem;
  width: 100%;
}
.reply-text-area {
  background: none;
  border: none;
  padding: 0.1rem 0;
  height: 1rem;
  line-height: 1rem;
  width: 100%;
}
.submit-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1.4rem;
  background-color: #fff;
  line-height: 1.4rem;
  text-align: center;
}
.submit-btn {
  text-align: center;
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  border-radius: 0.5rem;
  float: right;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  /* margin-top:.4rem; */
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
  